<template>
  <div class="p-1">
    <div v-if="!categoriesListLoaded" class="wrapper_tree-list d-flex align-items-center justify-content-center">
      <b-spinner class="d-flex align-items-center text-center" variant="primary" />
    </div>
    <vue-tree-list
      v-else
      ref="vueTreeList"
      :class="[$route.name !== 'categories' ? 'cursor-pointer' : 'cursor-auto']"
      :default-expanded="treeExpanded"
      :model="categories"
      class="wrapper_tree-list"
      default-leaf-node-name="new leaf"
      default-tree-node-name="Category"
      @click="onCategory"
      @delete-node="onDel"
    >
      <template v-slot:addTreeNodeIcon="">
        <b-link />
      </template>
      <template v-slot:leafNameDisplay="slotProps">
        <span
          :ref="'id-' + slotProps.model.id"
          :class="[categorySelected.some(cat => cat.id === slotProps.model.id) ? 'bg-gradient-primary' : '']"
          class="rounded p-25"
        >
          <span :class="fontSize[slotProps.model.nodeDepth]" class="font-weight-normal">
            {{ slotProps.model.name | trans }}
          </span>
        </span>
      </template>
      <template v-slot:delNodeIcon="slotProps" @click.stop>
        <div>
          <b-link
            v-show="$can('OPERATOR_CATEGORY_EDIT') && $route.name === 'categories'"
            :to="{ name: 'category-edit', params: { category_id: slotProps.model.id }, query: { action: 'edit' } }"
            class="pr-4"
            @click.stop
          >
            <font-awesome-icon v-b-tooltip.hover.bottom="$t('action.edit')" class="mr-50" icon="edit" />
          </b-link>
          <b-link v-show="$can('OPERATOR_CATEGORY_DELETE') && $route.name === 'categories'">
            <font-awesome-icon v-b-tooltip.hover.bottom="$t('action.delete')" class="text-danger" icon="trash" />
          </b-link>
        </div>
      </template>
      <span slot="addTreeNodeIcon" class="icon" />
      <span slot="addLeafNodeIcon" class="icon" />
      <span slot="editNodeIcon" class="icon" />
      <span slot="delNodeIcon" class="icon" />
      <span slot="leafNodeIcon" class="icon" />
      <span slot="treeNodeIcon" class="icon" />
    </vue-tree-list>
  </div>
</template>

<script>
import { Tree, VueTreeList } from 'vue-tree-list'

import useAppConfig from '@core/app-config/useAppConfig'
import { deleteCategoryRequest, fetchCategoriesRequest } from '@/request/globalApi/requests/categoryRequests'

export default {
  name: 'CategoriesTree',

  components: {
    VueTreeList,
  },

  props: {
    currentParent: {
      type: Array,
      default: () => [],
    },
    onlyActive: {
      type: Boolean,
      default: false,
    },
    parentNoSelectionnable: {
      type: Boolean,
      default: false,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
    checkAll: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      treeExpanded: false,
      categoriesListLoaded: false,
      newTree: {},
      categoriesBase: null,
      pathCategories: {},
      categories: null,
      categorySelected: [],
      translations: null,
      fontSize: {
        5: 'font-weight-normal',
        4: 'font-weight-normal',
        3: 'font-weight-bold',
        2: 'font-weight-bolder',
      },
    }
  },
  computed: {
    userLang() {
      return useAppConfig().language.value
    },
  },
  watch: {
    checkAll: {
      handler(val) {
        if (val) {
          this.checkChildren(this.categoriesBase)
        } else {
          this.categorySelected = []
        }
        this.$emit('category-selected', this.categorySelected)
      },
      deep: true,
    },
  },
  mounted() {
    this.APIFetchCategories()
  },
  methods: {
    APIFetchCategories() {
      const queryParams = this.onlyActive ? { active: true } : {}
      fetchCategoriesRequest(queryParams).then(response => {
        const categories = response.data.categories.map(category => this.assignAttrsToEachChild(category, 'children', { dragDisabled: true, addTreeNodeDisabled: true, addLeafNodeDisabled: true, editNodeDisabled: true }, [category.id]))
        this.categories = new Tree(categories)
        this.categoriesBase = categories
        this.categoriesListLoaded = true
        this.$emit('categories-list-loaded', true)
        setTimeout(() => {
          this.displayLoadedParent()
        }, 500)
      })
    },
    assignAttrsToEachChild(parent, children, attrs = {}, path = []) {
      const buildParent = this._cloneDeep(parent)
      if (buildParent[children]?.length) {
        buildParent[children] = parent[children].map(cat => {
          const buildPath = this._cloneDeep(path)
          buildPath.push(cat.id)
          return this.assignAttrsToEachChild(cat, children, attrs, buildPath)
        })
      } else {
        this.pathCategories[buildParent.id] = path
      }
      return Object.assign(buildParent, attrs)
    },
    checkChildren(cats) {
      cats.forEach(cat => {
        if (!this.parentNoSelectionnable || !cat.children.length) {
          this.categorySelected.push({ id: cat.id })
        }
        if (cat.children.length) {
          this.checkChildren(cat.children)
        }
      })
    },
    displayLoadedParent() {
      this.currentParent.forEach(catId => {
        // Select (display) selectionnable parents
        if (!this.parentNoSelectionnable && this.currentParent.includes(catId)) {
          this.$refs[`id-${catId}`]?.click()
        }
        // Select (display) and collapse no selectionnable parents
        if (this.parentNoSelectionnable && this.pathCategories[catId]) {
          this.pathCategories[catId].forEach(pathCatId => {
            this.$refs[`id-${pathCatId}`].click()
          })
        }
      })
    },
    onDel(category) {
      deleteCategoryRequest(category.id).then(({ remove }) => {
        if (typeof remove === 'function') {
          category.remove()
        } else {
          throw new Error('remove` action is not a function')
        }
      })
    },
    onCategory(currentCategory) {
      if (this.parentNoSelectionnable && currentCategory.children?.length) {
        currentCategory.toggle()
        return
      }
      if (this.$route.name !== 'categories') {
        const indexPreselected = this.categorySelected.findIndex(cat => cat.id === currentCategory.id)
        // if/else => delete/add category
        if (indexPreselected !== -1) {
          this.categorySelected.splice(indexPreselected, 1)
        } else {
          if (!this.multiselect) {
            // Reset categorySelected
            this.categorySelected = []
          }
          this.categorySelected.push(currentCategory)
        }

        this.$emit('category-selected', this.categorySelected)
      }
    },
    getNewTree() {
      const vm = this
      // eslint-disable-next-line no-underscore-dangle
      function _dfs(oldNode) {
        const newNode = {}

        // eslint-disable-next-line no-restricted-syntax
        for (const k in oldNode) {
          if (k !== 'children' && k !== 'parent') {
            newNode[k] = oldNode[k]
          }
        }

        if (oldNode.children && oldNode.children.length > 0) {
          newNode.children = []
          for (let i = 0, len = oldNode.children.length; i < len; i++) {
            newNode.children.push(_dfs(oldNode.children[i]))
          }
        }
        return newNode
      }

      vm.newTree = _dfs(vm.data)
    },
  },
}
</script>

<style lang="scss">
.wrapper_tree-list {
  min-height: 190px;
}

.cursor-pointer {
  cursor: pointer;
}
.cursor-auto {
  cursor: auto;
}
.vtl {
  padding: 1px;
  .vtl-drag-disabled {
    background-color: initial;
    &:hover {
      background-color: whitesmoke !important;
    }
  }
  .vtl-disabled {
    background-color: #d0cfcf;
  }
}
.vtl-icon:hover {
  color: #dcc181 !important;
}
</style>

<style lang="scss" scoped>
.icon {
  &:hover {
    cursor: pointer;
  }
}

.muted {
  color: gray;
  font-size: 80%;
}
</style>
